<template>
  <!-- 蒙层 -->
  <div class="dialog">
    <div class="content">
      <div class="heade">
        <p>云楚新能源服务协议</p>
      </div>
      <div class="con">
        <i
          class="width-index"
        />深圳云楚新能源电子商务有限公司及其关联方（以下合称"云楚新能源电子商务"，为本协议目的，关联方指一方通过合同或股权方式直接或间接持有另一方超过50%的权益，或两方同由第三方通过合同或股权方式直接或间接持有超过50%的权益）同意按照本协议的规定及其不时发布的操作规则提供基于互联网以及移动网的相关服务（以下称"网络服务"），为获得网络服务，服务使用人（以下称"用户"）应当同意本协议的全部条款并按照页面上的提示完成全部的注册程序。
        <br />
        <i class="width-index" />1.服务内容 <br />
        <i
          class="width-index"
        />1.1.云楚新能源电子商务网络服务的具体内容由云楚新能源电子商务根据实际情况提供。
        <br />
        <i
          class="width-index"
        />1.2.云楚新能源电子商务可不时提供收费网络服务，用户使用收费网络服务需要向云楚新能源电子商务支付一定的费用。对于收费的网络服务，云楚新能源电子商务会在用户使用之前给予用户明确的提示，只有用户根据提示确认其愿意支付相关费用，用户才能使用该等收费网络服务。如用户拒绝支付相关费用，则云楚新能源电子商务有权不向用户提供该等收费网络服务
        <br />
        <i class="width-index" />2.服务变更、中断或终止 <br />
        <i
          class="width-index"
        />2.1.用户理解云楚新能源电子商务需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，云楚新能源电子商务无需为此承担任何责任，但云楚新能源电子商务应尽可能事先进行通告。
        <br />
        <i
          class="width-index"
        />2.2.无论本协议是否有其他约定，如发生下列任何一种情形，云楚新能源电子商务有权随时中断或终止向用户提供本协议项下的网络服务（该网络服务包括但不限于收费及免费网络服务）而无需对用户或任何第三方承担任何责任或义务：
        <br />
        <i class="width-index" />2.2.1.用户提供的个人资料不真实； <br />
        <i
          class="width-index"
        />2.2.2.用户违反本协议中规定的使用规则及不时的更新、云楚新能源电子商务不时发布及更新的相关操作规则及要求；
        <br />
        <i
          class="width-index"
        />2.2.3.用户在使用收费网络服务时未按规定向云楚新能源电子商务支付相应的服务费；
        <br />
        <i
          class="width-index"
        />2.2.4.用户恶意操作、诋毁、损害云楚新能源电子商务的声誉； <br />
        <i
          class="width-index"
        />2.2.5.用户利用云楚新能源电子商务网络服务从事违反国家法律法规活动（包括但不限于洗钱等）。
        <br />
        <i
          class="width-index"
        />2.3.在2.2.5的情况下，如有关政府主管部门要求云楚新能源电子商务配合相关调查、采取相关监管、处罚措施，云楚新能源电子商务将尽全力配合，因此给用户带来的操作不能、操作不便等，云楚新能源电子商务不承担任何责任。
        <br />
        <i
          class="width-index"
        />2.4.如变更、中断或终止的网络服务属于免费网络服务，云楚新能源电子商务无需通知用户，也无需对任何用户或任何第三方承担任何责任；如变更、中断或终止的网络服务属于收费网络服务，云楚新能源电子商务应当在变更、中断或终止之前事先通知用户，并应向受影响的用户提供等值的替代性的收费网络服务，如用户不愿意接受替代性的收费网络服务，就该用户已经向云楚新能源电子商务支付的服务费，云楚新能源电子商务应当按照该用户实际使用相应收费网络服务的情况扣除相应服务费之后将剩余的服务费退还给该用户。
        <br />
        <i class="width-index" />3.使用规则 <br />
        <i
          class="width-index"
        />3.1.用户在申请使用云楚新能源电子商务网络服务时，必须向云楚新能源电子商务提供真实准确的个人资料，如个人资料有任何变动，须及时根据云楚新能源电子商务的业务规则向云楚新能源电子商务更新。
        <br />
        <i
          class="width-index"
        />3.2.用户注册成功后，云楚新能源电子商务将向每个用户配发一个用户帐号、密码，用户应当对以其用户帐号进行的所有活动和事件负法律责任。
        <br />
        <i
          class="width-index"
        />3.3.用户应妥善保管其账号、密码，不应将其帐号、密码转让或出借予他人使用。用户并将以不时修改密码、更新客户证书、U盾等方式确保其账户安全。如用户发现其帐号遭他人非法使用、密码泄露等情况，应立即通知云楚新能源电子商务。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，云楚新能源电子商务不承担任何责任。
        <br />
        <i
          class="width-index"
        />3.4.用户同意云楚新能源电子商务有权在提供网络服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息（包括但不限于在云楚新能源电子商务网站的任何页面上投放广告），并且，用户同意接受云楚新能源电子商务通过电子邮件、短信或其他方式向用户发送产品或其他相关商业信息。
        <br />
        <i
          class="width-index"
        />3.5.用户在使用云楚新能源电子商务网络服务过程中，必须遵循以下原则： <br />
        <i class="width-index" />3.5.1.遵守中国有关的法律和法规； <br />
        <i
          class="width-index"
        />3.5.2.遵守本协议中规定的使用规则及不时的更新、云楚新能源电子商务不时发布及更新的相关操作规则及要求；
        <br />
        <i class="width-index" />3.5.3.不得为任何非法目的而使用网络服务系统；
        <br />
        <i
          class="width-index"
        />3.5.4.不得以任何形式使用云楚新能源电子商务网络服务侵犯云楚新能源电子商务的商业利益；
        <br />
        <i
          class="width-index"
        />3.5.5.不得利用云楚新能源电子商务网络服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；
        <br />
        <i
          class="width-index"
        />3.5.6.不得利用云楚新能源电子商务网络服务系统进行任何不利于云楚新能源电子商务的行为；
        <br />
        <i
          class="width-index"
        />3.6.用户理解，云楚新能源电子商务仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。
        <br />
        <i
          class="width-index"
        />3.7.云楚新能源电子商务有权对用户使用云楚新能源电子商务网络服务（该网络服务包括但不限于收费及免费网络服务）的情况进行审查和监督(包括但不限于对用户存储在云楚新能源电子商务的内容进行审核)，如用户在使用网络服务时违反任何上述规定，云楚新能源电子商务或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于暂停或终止用户使用网络服务的权利）以减轻用户不当行为造成的影响。
        <br />
        <i
          class="width-index"
        />3.8.云楚新能源电子商务针对某些特定的云楚新能源电子商务网络服务的使用通过各种方式（包括但不限于网页公告、电子邮件、短信提醒等）作出的任何声明、通知、警示等内容视为本协议的一部分，用户如使用该等云楚新能源电子商务网络服务，视为用户同意该等声明、通知、警示的内容。
        <br />
        <i
          class="width-index"
        />3.9.在云楚新能源电子商务系统正常运行情况下，云楚新能源电子商务负责及时准确地处理用户发送的业务指令，并及时向用户提供有关服务。
        <br />
        <i
          class="width-index"
        />3.10.云楚新能源电子商务根据用户的业务指令办理业务，对所有使用用户账户、密码进行的操作均视为用户所为，该操作所产生的电子信息记录无需用户书面签章确认均作为云楚新能源电子商务处理网络业务的有效凭据。
        <br />
        <i
          class="width-index"
        />3.11.如用户因云楚新能源电子商务网络系统差错、故障或其他原因获得不当得利的，应当立即返还不当得利，用户不可撤销地授权云楚新能源电子商务从其账户（如有）中直接扣划用户的不当得利所得。
        <br />
        <i class="width-index" />4.知识产权 <br />
        <i
          class="width-index"
        />4.1.云楚新能源电子商务提供的网络服务中包含的任何文本、图片、图形、音频和/或视频资料均受版权、商标和/或其它财产所有权法律的保护，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可作为私人和非商业用途而保存在某台计算机内。云楚新能源电子商务不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向用户或任何第三方负责。
        <br />
        <i
          class="width-index"
        />4.2.云楚新能源电子商务为提供网络服务而使用的任何软件（包括但不限于软件中所含的任何图象、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程、反向编译或反汇编。
        <br />
        <i class="width-index" />5.隐私保护 <br />
        <i
          class="width-index"
        />5.1.保护用户隐私是云楚新能源电子商务的一项基本政策，云楚新能源电子商务保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在云楚新能源电子商务的非公开内容，但下列情况除外：
        <br />
        <i class="width-index" />5.1.1.事先获得用户的授权； <br />
        <i class="width-index" />5.1.2.根据有关的法律法规要求； <br />
        <i class="width-index" />5.1.3.按照相关政府主管部门、司法机关的要求；
        <br />
        <i class="width-index" />5.1.4.为维护云楚新能源电子商务的合法权益。 <br />
        <i
          class="width-index"
        />5.2.云楚新能源电子商务可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与云楚新能源电子商务同等的保护用户隐私的责任，则云楚新能源电子商务有权将用户的注册资料等提供给该第三方。
        <br />
        <i
          class="width-index"
        />5.3.在不透露单个用户隐私资料的前提下，云楚新能源电子商务有权对整个用户数据库进行分析或调查研究，并对用户数据库进行商业上的利用。
        <br />
        <i class="width-index" />6.免责申明 <br />
        <i
          class="width-index"
        />6.1.用户明确同意其使用云楚新能源电子商务网络服务所存在的风险将完全由其自己承担；因其使用云楚新能源电子商务网络服务而产生的一切后果也由其自己承担，云楚新能源电子商务对用户不承担任何责任。
        <br />
        <i
          class="width-index"
        />6.2.云楚新能源电子商务不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由云楚新能源电子商务实际控制的任何网页上的内容，云楚新能源电子商务不承担任何责任。
        <br />
        <i
          class="width-index"
        />6.3.云楚新能源电子商务因以下特殊情况没有正确或及时执行用户提交的业务指令，不承担任何责任：
        <br />
        <i class="width-index" />6.3.1.用户未能按照甲方的有关业务规定正确操作；
        <br />
        <i class="width-index" />6.3.2.用户的行为出于欺诈或其他非法目的； <br />
        <i
          class="width-index"
        />6.3.3.云楚新能源电子商务遇到不可抗力、计算机黑客袭击、系统故障、通讯故障、网络拥堵、供电系统故障、电脑病毒、恶意程序攻击等情况时。
        <br />
        <i class="width-index" />7.违约赔偿 <br />
        <i
          class="width-index"
        />7.1.除本协议另有约定外，如因云楚新能源电子商务违反有关法律、法规或本协议项下的任何条款而给用户造成损失，云楚新能源电子商务同意承担由此造成的损害赔偿责任。
        <br />
        <i
          class="width-index"
        />7.2.用户同意保障和维护云楚新能源电子商务及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给云楚新能源电子商务或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任
        <br />
        <i class="width-index" />8.协议修改 <br />
        <i
          class="width-index"
        />8.1.云楚新能源电子商务有权随时修改本协议的任何条款，一旦本协议的内容发生变动，云楚新能源电子商务将会直接相关网站上公布修改之后的协议内容，该公布行为视为云楚新能源电子商务已经通知用户修改内容。云楚新能源电子商务也可通过其他适当方式向用户提示修改内容。
        <br />
        <i
          class="width-index"
        />8.2.如果不同意云楚新能源电子商务对本协议相关条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受云楚新能源电子商务对本协议相关条款所做的修改。
        <br />
        <i class="width-index" />9.通知送达 <br />
        <i
          class="width-index"
        />9.1.本协议项下云楚新能源电子商务对于用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。
        <br />
        <i class="width-index" />10.法律管辖 <br />
        <i
          class="width-index"
        />10.1.本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。
        <br />
        <i
          class="width-index"
        />10.2.如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可将争议提交深圳仲裁委员会按届时的仲裁规则进行仲裁。败诉方并应承担胜诉方相关律师费。仲裁结果是终局的，对双方均具有约束力。
        <br />
        <i class="width-index" />11.其他规定 <br />
        <i
          class="width-index"
        />11.1.本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。
        <br />
        <i
          class="width-index"
        />11.2.如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
        <br />
        <i class="width-index" />12.特别提示 <br />
        <i
          class="width-index"
        />12.1.在用户确认同意本协议之前，用户确认，其已认真阅读本协议并已充分理解本协议内容。在确信其已完全理解的情况下，用户确认其完全同意本协议的全部条款与内容。用户在进行注册程序过程中表示用户完全接受本协议项下的全部条款。
        <br />
      </div>
      <div class="foote">
        <p @click="Login">同意并返回</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },
  methods: {
    // 跳转到登录页面
    Login() {
      this.$router.back();
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.dialog {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 0.3rem;
  // background-color: #f0eded;

  .heade {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    // padding: 0 1rem;
    line-height: 1rem;
    z-index: 99;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    p {
      color: #bc051d;
      font-size: 0.5rem;
    }
  }
  .con {
    margin-top: 0.6rem;
    line-height: 0.5rem;
    padding: 0.5rem 0.3rem 1rem;
  }
  .foote {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #eeeeee;
    width: 100%;
    line-height: 40px;
    padding: 0 20px;
    color: #333333;
  }
}
.width-index {
  display: inline-block;
  width: 0.3rem;
}
</style>
